import { Form } from "@components/contact-form"
import { MaxWidth } from "@components/layout/max-width"
import { LogoSVG } from "@components/LogoSVG"
import { SocialLinks } from "@components/social-links"
import css from "@emotion/css"
import { colors } from "@theme/"
import { Link, useStaticQuery, graphql } from "gatsby"
import React from "react"

const style = css`
  background: black;
  color: white;
  h2 {
    color: ${colors.blue};
    margin-bottom: 2em;
  }
  h3 {
    color: white;
    padding: 0;
    margin-bottom: 0.75em;
  }
  p {
    margin: 0;
    padding: 0;
  }

  .footer-logo {
    color: white;
    width: 6em;
    text-align: center;
    margin: 0 auto;
    padding-bottom: 2em;
    display: block;
    @media screen and (min-width: 40em) {
      display: none;
    }
  }
`

const layout = css`
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 70em) {
    flex-wrap: wrap;
  }
`

function Contact() {
  return (
    <section css={style} id="contact-us">
      <MaxWidth
        css={css`
          padding-top: 3em;
        `}
      >
        <h2>Contact us</h2>
        <div css={layout}>
          <GetInTouch />
          <Map />
          <Form />
        </div>
      </MaxWidth>
      <Link to="/">
        <LogoSVG className="footer-logo" />
      </Link>
    </section>
  )
}

export { Contact }

const map = css`
  @media screen and (max-width: 70em) {
    order: 3;
    margin-top: 3em;
  }
  flex: 1 1 25rem;
  margin-right: 3em;
  @media screen and (max-width: 68em) {
    margin-right: 0;
  }
  iframe {
    width: 100%;
    height: 25rem;
  }
`

function Map() {
  return (
    <div css={map}>
      <iframe
        title="Google Map"
        frameBorder="0"
        src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB9auqBlOMN9s6L0SSVDOu-1gHJGyW5CMg&q=Frontier Australia, Suite 57 Jones Bay Wharf, Upper Deck 26-32 Pirrama Road Pyrmont NSW 2009"
        allowFullScreen
      ></iframe>
    </div>
  )
}

const handleCTAClick = e => {
  switch(e.target.id) {
    case 'landline-phone-cta':
      setTimeout(function(){ga('send','event','landline-phone', 'click')},600);
      try{
        __adroll.track("pageView", {"segment_name":"b8bfd231"})
      } catch(err) {}
      break;
    case 'mobile-phone-cta':
      setTimeout(function(){ga('send','event','mobile-phone', 'click')},600);
      try{
        __adroll.track("pageView", {"segment_name":"d40d6a5c"})
      } catch(err) {}
      break;
    case 'email-address-cta':
      setTimeout(function(){ga('send','event','email-address', 'click')},600);
      try{
        __adroll.track("pageView", {"segment_name":"eead65ad"})
      } catch(err) {}
      break;  
  }
}

const getInTouch = css`
  flex: 0 1 18.75em;
  min-width: 13em;
  a {
    color: white;
  }
  section {
    :first-of-type {
      margin-top: 0;
    }
    margin-top: 2.5em;
    :last-of-type {
      margin-top: auto;
    }

    svg {
      margin-right: 0.5em;
    }
  }
  .logo {
    @media screen and (max-width: 42em) {
      display: none;
    }
  }
`

const socialLinks = css`
  display: flex;
  svg {
    height: 2em;
    color: black;
  }
`
function GetInTouch() {
  const { data } = useStaticQuery(graphql`
    query DanQuery {
      data: datoCmsContactPerson(name: { eq: "Daniel O'Brien" }) {
        name
        phone
        mobile
        email
        companyPosition
        color {
          hex
        }
      }
    }
  `)

  return (
    <div css={getInTouch}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 102%;
        `}
      >
        <section>
          <h3>Get in touch</h3>
          <p>
            {data.name}
            <br />
            <span style={{ color: data.color.hex }}>
              {data.companyPosition}
            </span>
            <br />
            <span style={{ color: data.color.hex }}>D:</span> <a onClick={handleCTAClick} id="landline-phone-cta" href={`tel:${data.phone}`} >{data.phone}</a>
            <br />
            <span style={{ color: data.color.hex }}>M:</span> <a onClick={handleCTAClick} id="mobile-phone-cta" href={`tel:${data.mobile}`} >{data.mobile}</a>
            <br />
            <a onClick={handleCTAClick} id="email-address-cta" href={`mailTo:${data.email}?subject=Website`}>{data.email}</a>
          </p>
        </section>

        <section>
          <h3>Visit us</h3>
          <p>
            Suite 57
            <br />
            Jones Bay Wharf, Upper Deck
            <br />
            26-32 Pirrama Road
            <br />
            Pyrmont NSW 2009
          </p>
        </section>

        <section>
          <h3>Connect</h3>
          <div css={socialLinks}>
            <SocialLinks />
          </div>
        </section>

        <section
          className="logo"
          css={css`
            svg {
              height: 4.5em;
            }
          `}
        >
          <LogoSVG />
        </section>
      </div>
    </div>
  )
}
