import React from "react"

export function SocialLinks() {
  return (
    <>
      <a
        href="https://www.linkedin.com/company/frontier-australia"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          className="linkedin"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 34 34"
        >
          <defs>
            <path id="a" d="M16.64 34h16.5V.5h-33V34z" />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g transform="translate(0 -.5)">
              <mask id="b" fill="white">
                <use xlinkHref="#a" />
              </mask>
              <path
                fill="white"
                d="M5.14.5s-5 0-5 5V29s0 5 5 5h23s5 0 5-5V5.5s0-5-5-5h-23z"
                mask="url(#b)"
              />
            </g>
            <path
              fill="currentColor"
              d="M17.9 13.85c.28-.3.5-.6.78-.88.68-.7 1.53-1.1 2.5-1.22.95-.12 1.87-.05 2.77.28 1.06.4 1.85 1.08 2.4 2.05.63 1.13.82 2.36.82 3.62v7.7h-4.5v-7.03c0-.6-.05-1.18-.26-1.74-.32-.98-1.13-1.46-2.2-1.4-1.06.08-1.9.85-2.16 1.76-.1.32-.1.7-.1 1.06v7.34h-4.5V12h4.3c.15 0 .2.05.2.2v1.65zm-7-1.85v13.42H6.6c-.16 0-.2-.05-.2-.2v-13c0-.17.04-.2.2-.2h4.3zm-2.27-1.8c-.88 0-1.64-.3-2.14-1.05-.88-1.3-.26-3 1.24-3.44.87-.25 1.72-.2 2.48.3.76.55 1.04 1.3.93 2.2-.1.9-.62 1.5-1.48 1.8-.34.13-.7.2-1.07.2"
            />
          </g>
        </svg>
      </a>{" "}
      <a
        href="https://www.facebook.com/FrontierAu"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          className="facebook"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 32 34"
        >
          <g fill="none" fill-rule="evenodd">
            <path
              fill="white"
              d="M5 0S0 0 0 5v23.5s0 5 5 5h21.64s5 0 5-5V5s0-5-5-5H5z"
            />
            <path
              fill="currentColor"
              d="M20.7 5v3.74h-1.95c-1.06 0-1.43.36-1.44 1.43v1.92h3.47l-.4 3.75h-3.05v10.88h-4.5V15.87h-2.18V12.1h2.17v-.56c.07-1.1.02-2.24.2-3.33.32-1.86 1.58-3 3.48-3.14 1.22-.1 2.45-.04 3.67-.05h.54"
            />
          </g>
        </svg>
      </a>{" "}
      <a
        href="https://www.instagram.com/frontieraustralia/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          id="Layer_1"
          data-name="Layer 1"
          viewBox="0 0 30.69 31.16"
          className="insta"
        >
          <path
            fill="#fff"
            d="M389.65 283.84s-4.65 0-4.65 4.66v21.85s0 4.65 4.65 4.65H411s4.65 0 4.65-4.65V288.5s0-4.66-4.65-4.66z"
            transform="translate(-385 -283.84)"
          />
          <path
            d="M413.08 294.08a7.52 7.52 0 0 0-5.56-7.15 7.08 7.08 0 0 0-1.54-.25h-11.2l-.5.05a7.11 7.11 0 0 0-2.95 1 7.48 7.48 0 0 0-3.44 4.43 6.91 6.91 0 0 0-.29 2v10.7a7.24 7.24 0 0 0 1.95 4.94 7.34 7.34 0 0 0 2.6 1.86 7.22 7.22 0 0 0 2.12.55c.32 0 .65.05 1 .05h10.34a7.56 7.56 0 0 0 7.17-5.29 7.34 7.34 0 0 0 .33-2.15V302q0-4-.03-7.92zm-11.13-5h3.69a5 5 0 0 1 5 4.5 4.17 4.17 0 0 1 0 .61v10.42a5 5 0 0 1-1.74 3.87 5 5 0 0 1-2.7 1.21h-11.14a5.06 5.06 0 0 1-4.92-4 5.12 5.12 0 0 1-.12-1.09v-10.42a5 5 0 0 1 2.9-4.6 5.2 5.2 0 0 1 2.23-.49H402z"
            transform="translate(-385 -283.84)"
            fill="currentColor"
          />
          <path
            d="M400.33 306.4a7 7 0 0 0 0-14 7 7 0 0 0 0 14zm-4.54-7a4.56 4.56 0 0 1 4.55-4.56 4.57 4.57 0 0 1 3.23 7.79 4.52 4.52 0 0 1-3.23 1.33 4.56 4.56 0 0 1-4.55-4.54zM406.77 294.38a1.7 1.7 0 0 0 0-3.39 1.69 1.69 0 0 0-1.69 1.68 1.71 1.71 0 0 0 1.68 1.71z"
            transform="translate(-385 -283.84)"
            fill="currentColor"
          />
        </svg>
      </a>
    </>
  )
}
