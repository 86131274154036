import { Global, css } from "@emotion/core"
import React from "react"
import "normalize.css"

const style = css`
  body {
    font-size: 16px;
    background: black;
    height: 100%;
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    line-height: 1.5;
    font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir,
      helvetica neue, helvetica, ubuntu, roboto, noto, segoe ui, arial,
      sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  h1,
  h2,
  h3 {
    font-family: "Montserrat";
  }
  /*Underline effect in intro*/
  .grad-underline {
    position: relative;
  }
  .grad-underline:after {
    height: 2px;
    position: absolute;
    background: linear-gradient(-90deg, #00c5ff 0%, #02e280 100%);
    width: 100%;
    content: "";
    bottom: -0.1em;
    left: 0;
    transform-origin: left;
    transition: 0.5s linear;
  }
  /*Underline on hover for links with grad-underline*/
  /*class, as well as links with a child .grad-underline*/
  a.grad-underline:after,
  a .grad-underline:after {
    transform: scaleX(0);
    visibility: hidden;
  }
  a.grad-underline:hover:after,
  a.grad-underline:focus:after,
  a:hover .grad-underline:after,
  a:focus .grad-underline:after {
    visibility: visible;
    transform: scaleX(1);
  }
`

export function GlobalStyle() {
  return <Global styles={style} />
}
