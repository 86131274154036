import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from "gatsby-source-datocms"

export function Meta() {
  const { data } = useStaticQuery(graphql`
    query Metatags {
      data: datoCmsHeroSection {
        seoMetaTags {
          ...GatsbyDatoCmsSeoMetaTags
        }
      }
    }
  `)
  return (
    <HelmetDatoCms
      seo={{
        tags: [
          ...data.seoMetaTags.tags,
          { tagName: "title", content: "Media & Advertising Agency Sydney | Frontier Australia" },
        ],
      }}
    />
  )
}
