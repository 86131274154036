import css from "@emotion/css"
import { colors } from "@theme/"
import React, { useState } from "react"
import arrow from "../images/arrow.png"

const form = css`
  color: white;
  flex: 1 1 19em;
  margin-bottom: 0;

  @media screen and (max-width: 40em) {
    margin-right: 0;
  }
  @media screen and (max-width: 41em) {
    margin-top: 3em;
  }
  input,
  textarea,
  button {
    font-family: Montserrat;
  }
  input,
  textarea {
    background: #262626;
    padding: 0.75em 1em;
    display: block;
    margin-top: 1em;
    border: 0;
    outline: 0;
    width: 100%;
    color: white;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: rgba(255, 255, 255, 0.9);
    }
  }
  button {
    color: white;
    cursor: pointer;
    background: ${colors.blue};
    padding: 0.75em 3em;
    font-weight: 700;
    margin-top: 2em;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid transparent;
    margin-left: auto;
    :active,
    :focus {
      outline: 0;
      border: 1px solid ${colors.lightBlue};
    }
    img {
      margin-left: 0.5em;
      height: 12px;
    }
  }
`

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export function Form() {
  const [formState, setFormState] = useState({
    name: "",
    company: "",
    phone: "",
    email: "",
    message: "",
  })
  const [submitted, setSubmitted] = useState(false)

  const handleSubmit = e => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formState }),
    })
      .then(() => setSubmitted(true))
      .then(() => {
        setTimeout(function(){ga('send','event','contact-form', 'submit')},600);
        try{
          __adroll.track("pageView", {"segment_name":"ed40f3d2"})
          } catch(err) {}
      })
      .catch(error => {
        setSubmitted(false)
        console.log(error)
      })

    e.preventDefault()
  }

  const handleChange = e =>
    setFormState({ ...formState, [e.target.name]: e.target.value })

  return (
    <form name="contact" onSubmit={handleSubmit} css={form} data-netlify="true">
      {!submitted && (
        <>
          <h3>Make an enquiry</h3>
          <input
            type="text"
            name="name"
            required
            placeholder="Name"
            onChange={handleChange}
          />
          <input
            type="text"
            name="company"
            placeholder="Company"
            onChange={handleChange}
          />
          <input
            type="tel"
            name="phone"
            required
            placeholder="Phone"
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            required
            placeholder="Email"
            onChange={handleChange}
          />
          <textarea
            onChange={handleChange}
            name="message"
            id="message"
            cols="30"
            rows="10"
            placeholder="Message"
          ></textarea>
          <input type="hidden" name="form-name" value="contact" />
          <button type="submit">
            Send
            <img src={arrow} />
          </button>
        </>
      )}

      {submitted && (
        <>
          <h3>Form submitted</h3>
          <p>Thank you. We will be in contact with you shortly.</p>
        </>
      )}
    </form>
  )
}
