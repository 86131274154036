import React from "react"
import css from "@emotion/css"

const style = css`
  max-width: 67rem;
  margin: auto;
  padding: 4rem 1rem;

  > h2 {
    font-size: 2.25rem;
  }
`

export function MaxWidth(props) {
  return <div css={style} {...props} />
}
